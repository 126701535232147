import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
//import About from './About';
import logo from './logo.png'

function App() {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [releases, setReleases] = useState([]);
  const [recArtists, setRecArtists] = useState([]);
  const [recTitles, setRecTitles] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [visible, setVisible] = useState(false);
  const [label, setLabel] = useState('');
  const [recommendationLabel, setRecommendationLabel] = useState('');
  const [aboutVisible, setAboutVisible] = useState(true);
  const [aboutClosed, setAboutClosed] = useState(false);




  useEffect(() => {
    if (aboutClosed) {
      const fullLabel = 'Enter discogs url:';
      let currentLabel = '';
      const intervalId = setInterval(() => {
        if (currentLabel.length < fullLabel.length) {
          currentLabel += fullLabel[currentLabel.length];
          setLabel(currentLabel);
        } else {
          clearInterval(intervalId);
        }
      }, 30);
      return () => clearInterval(intervalId);
    }
  }, [aboutClosed]);

  const fetchReleases = async (releaseId) => {
    try {
      setReleases([]);  // clear previous recommendations
      setRecArtists([]);  // clear previous recommendations
      setRecTitles([]);  // clear previous recommendations
      setNotFound(false); // reset not found state
      const response = await axios.get(`https://rec.digassistant.xyz/recommend/${releaseId}`);
      //const response = await axios.get(`http://localhost:8080/recommend/${releaseId}`);
      if (response.data.status === 404) {  // Here check the status in the response body
        setNotFound(true);
      } else {
        const inputLabel = response.data.label;

        const recommendations = response.data.recommendations.map((releaseId, index) => ({
          releaseId,
          artist: response.data.recommendations_artist[index],
          title: response.data.recommendations_title[index],
          label: response.data.recommendations_label[index],
        }));


        setReleases(recommendations.map(({ releaseId }) => releaseId));
        setRecArtists(recommendations.map(({ artist }) => artist));
        setRecTitles(recommendations.map(({ title }) => title));
        setUrl('');
      }
    } catch (error) {
      console.error('Error:', error);
      setNotFound(true);  // If there's an error (network issue, etc.) we setNotFound to true
    }
  }



  useEffect(() => {
    if (releases.length > 0) {
      setVisible(false);
      setTimeout(() => setVisible(true), 1000);
      const fullLabel = 'You might like:';
      let currentLabel = '';
      const intervalId = setInterval(() => {
        if (currentLabel.length < fullLabel.length) {
          currentLabel += fullLabel[currentLabel.length];
          setRecommendationLabel(currentLabel);
        } else {
          clearInterval(intervalId);
        }
      }, 90);
    }
  }, [releases]);

  function isValidUrl(url) {
    const regex = /^(https?:\/\/)?(www\.)?discogs\.com\.?([-a-zA-Z0-9@:%._\+~#=\/]*)$/i;
    return regex.test(url);
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    setUrlError("")
    setError("");
    setNotFound("");
    if (isValidUrl(url)) {

      let fullUrl = url;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // If the URL does not start with 'http://' or 'https://', add 'http://'
        fullUrl = 'http://' + url;
      }
      const parsedUrl = new URL(fullUrl);
      const pathParts = parsedUrl.pathname.split('/');
      if (pathParts[1] !== 'release' || !pathParts[2]) {
        setError('Invalid URL. It should be of the format: https://www.discogs.com/release/<id-string> Ensure you did not enter the master release!');
      } else {
        const releaseId = pathParts[2].split('-')[0];
        setError('');
        fetchReleases(releaseId);
      }
    } else {
      setUrlError("Please enter a valid discogs url")
    }
  }

  useEffect(() => {
    if (notFound) {
      setVisible(true);
    } else if (releases.length > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [notFound, releases]);


  return (
    <div className="App">
      {aboutVisible ? (
        <div className="about-section">
          <h2>Timehri Dig Assistant:</h2>
          <p>Welcome to the Timehri Dig Assistant - a sophisticated machine learning model, designed to help you explore the depths of Discogs.</p>
          <button className="about-close-button" onClick={() => { setAboutVisible(false); setAboutClosed(true); }}>Enter here</button>

          <br></br>

          <h4>Usage:</h4>
          <ul>
            <li>Submit a Discogs release URL to generate relevant music recommendations. Click "dive" to explore the wormhole further based on the chosen item.</li>
            <br></br>
            <li>The recommendations generated are algorithmic and do not explicitly reflect the preferences of Timehri Records </li>
          </ul>

          <h4>Current Limitations:</h4>
          <ul>
            <li>Listings pre Jan 2022 only</li>
            <br></br>
            <li>The model is specific to (mostly) electronic vinyl music. It covers most styles, from House, Techno, Electro, Grime, Dancehall, Reggae, Dub, Baltimore Club, IDM, UK Garage & Jungle, to Trance and more. It might yield no results for some releases not covered yet</li>
            <br></br>
            <li>This is a Beta model.</li>
          </ul>
          <h4>Info:</h4>
          <p>Built using Rust, Python and an array of distributed data processing frameworks</p>
        </div>
      ) : (
        <>

          <a href="https://www.timehri.world/music" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleSubmit} noValidate className="url-form">
            <span className="label">{label}</span>
            <input id="url-input" type="url" value={url} onChange={(e) => setUrl(e.target.value)} required />
            <button type="submit" className="submit-button">Submit</button>
          </form>
          {urlError && <div className="error-message">{urlError}</div>}
          <div className={`recommendations ${visible ? 'visible' : 'hidden'}`}>
            {notFound ? (
              <span className="label">No candidates found. Congratulations you've reached the bottom of the wormhole, check back for more explorations soon...</span>
            ) : (
              <>
                <span className="label">{recommendationLabel}</span>
                {releases.map((release, index) => {
                  const artist = recArtists[index];
                  const title = recTitles[index];
                  return (
                    <div className="recommendation-container" key={release}>
                      <a
                        href={`https://www.discogs.com/release/${release}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="recommendation"
                      >
                        {`${artist} - ${title}`}
                      </a>
                      <button onClick={() => fetchReleases(release)} className="dive-button">
                        dive
                      </button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};


export default App;

